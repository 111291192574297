import { Box } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Client from '@pw/components/Client';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import RequestID from '@pw/components/RequestID';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import {
	useAccountChain,
	useAccountPlatform,
	useCompanySKUs,
} from '@pw/redux/containers/User';
import { upsertRequestThunk } from '@pw/redux/thunks/request';
import { updateSKUsThunk } from '@pw/redux/thunks/sku';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import { useDispatch } from 'react-redux';

function RequestForm({
	id,
	requestType,
	entity,
	title,
	changeSet,
	readonly = false,
	edit = false,
	onCancel,
	onBeforeSave,
	onSave,
	children,
}) {
	const dispatch = useDispatch();
	const skuList = useCompanySKUs();
	const platform = useAccountPlatform();
	const chain = useAccountChain();
	// const sameCompany = useCheckCompany(entity);
	const updateTrackedSKUsInList = (skuDestinations) => {
		if (requestType !== REQUEST_TYPES.delivery) return;
		const updatedTrackedSKUs = skuDestinations?.filter(
			({ sku_type }) => sku_type === SKU_TYPES.TRACKED,
		);
		if (!updatedTrackedSKUs.length) return;
		const updatedSKUList = skuList.map((existingSKU) => {
			const matchingSKU = updatedTrackedSKUs.find(
				(newSKU) => newSKU.path === existingSKU.path,
			);

			if (matchingSKU) {
				return {
					...existingSKU,
					properties: {
						...(existingSKU.properties ?? {}),
						last_number:
							matchingSKU?.last_number ?? existingSKU?.properties?.last_number,
					},
				};
			}

			return existingSKU;
		});

		dispatch(updateSKUsThunk(updatedSKUList));
		console.log('Updated SKU list:', updatedSKUList);
	};

	const handleSubmit = async (values) => {
		// if (sameCompany) {
		// 	downloadJson(values?.properties, 'form-data.json');
		// 	returnl
		// }

		const updated = onBeforeSave ? onBeforeSave(values) : values;

		const request = {
			...entity,
			...updated,
			request_type: requestType,
			chain,
			platform,
		};

		console.log('Submitting', request);
		dispatch(upsertRequestThunk(request))
			.unwrap()
			.then((response) => {
				updateTrackedSKUsInList(request?.sku_destinations);
				onSave(response);
			});
	};

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			enableReinitialize
			readonly={readonly}
			edit={edit}
		>
			<FormWrapper>
				<RequestID type={requestType} title={title} label='ID.' />

				{children}

				{!readonly && <Client name='client' />}

				{!readonly && (
					<>
						<Errors />

						<Box className='action-buttons'>
							{onCancel && (
								<TextButton
									size='small'
									handleClick={onCancel}
									color='secondary'
								>
									Cancel
								</TextButton>
							)}
							<FilledButton type='submit' size='small'>
								{id?.length ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</>
				)}
			</FormWrapper>
		</FormikForm>
	);
}

export default RequestForm;
