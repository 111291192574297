import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import PalletListItem from '@pw/components/AssetListItem/PalletListItem';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import Pagination from '@pw/components/Pagination';
import { Body1 } from '@pw/components/Typography';
import { ASSET_EDIT_URL, ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { vh_50 } from '@pw/styles/heights';
import { useAssetsLazyQueryPaginated } from '@pw/utilities/hooks/service/useAssetsLazyQuery';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './Toolbar';
import { FormikSelect } from '../Forms/FormikForm';
import {
	useCompanyPartners,
	useIsBrokerCompany,
} from '@pw/redux/containers/User';
import { SearchAssetListItemComponent } from '../AssetListItem/SearchAssetListItemComponent';

const styles = {
	loader: {
		zIndex: 1000,
		color: '#fff',
	},
	content: {
		...vh_50,
		overflow: 'scroll',
		display: 'flex',
		flexDirection: 'column',
		padding: 0.2,
		paddingTop: 2,
	},
	emptyContent: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		p: 8,
	},
};

function Table() {
	const navigate = useNavigate();
	const { values } = useContext(FormikContext);
	const isBrokerCompany = useIsBrokerCompany();

	const [pageSize, setPageSize] = useState('5');

	const reqParams = useMemo(() => {
		const { location = {}, partner_id } = values ?? {};

		if (isBrokerCompany) {
			return { partner_id, limit: Number(pageSize) };
		}

		const { facility_id, bay, row, level } = location;

		let ff;
		let rf;
		let lf;

		if (facility_id) {
			if (bay) {
				ff = `${facility_id}/${bay}`;
				if (row) {
					rf = row;
					if (level) {
						lf = level;
					}
				}
			} else {
				ff = facility_id;
			}
		}

		return {
			facility_id: ff,
			row: rf,
			level: lf,
			limit: Number(pageSize),
		};
	}, [
		values.location?.bay,
		values.location?.facility_id,
		values.location?.level,
		values.location?.row,
		pageSize,
		values.partner_id,
	]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useAssetsLazyQueryPaginated(reqParams);

	const goToItem = useCallback((params) => {
		const id = params?.path ?? 'new';
		const asset_type = isBrokerCompany
			? params?.token_type
			: params?.asset_type;
		const route = ASSET_EDIT_URL[asset_type];
		navigate(`${route}/${id}`);
	}, []);

	return (
		<>
			<Pagination
				hasNextPage={hasNext}
				hasPrevPage={hasPrev}
				onPressNextPage={loadNext}
				onPressPrevPage={loadPrev}
				onChangePageSize={setPageSize}
			/>
			{!isLoading && (
				<Stack className='list'>
					{page.map((asset) =>
						asset?.asset_type === ASSET_TYPES.pallet ? (
							<PalletListItem
								key={asset.path}
								item={asset}
								onClick={goToItem}
							/>
						) : (
							<AssetListComponentV2
								key={asset.path}
								item={asset}
								onClick={goToItem}
							>
								{isBrokerCompany && (
									<SearchAssetListItemComponent item={asset} />
								)}
							</AssetListComponentV2>
						),
					)}
					{page.length === 0 && (
						<Box sx={styles.emptyContent}>
							<Body1>Assets not found</Body1>
						</Box>
					)}
				</Stack>
			)}
			{isLoading && (
				<Box sx={styles.emptyContent}>
					<LoadingSmall message='Loading assets' />
				</Box>
			)}
		</>
	);
}

function ExplorerTable() {
	return (
		<Stack spacing={2}>
			<Toolbar />
			<Table />
		</Stack>
	);
}

function SelectCompanyPartners() {
	const partners = useCompanyPartners();
	const partnerOptions = useMemo(
		() =>
			partners
				.filter((i) => i.my_partner_id)
				.map((i) => ({ value: i.my_partner_id, label: i.company_name })),
		partners,
	);
	return (
		<FormikSelect
			fullWidth
			options={partnerOptions}
			label='Companies'
			name='partner_id'
		/>
	);
}

export function BrokerTable() {
	return (
		<Stack spacing={2}>
			<SelectCompanyPartners />
			<Table />
		</Stack>
	);
}

export default ExplorerTable;
