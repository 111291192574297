import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Address from '@pw/components/Company/Address';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { H5 } from '@pw/components/Typography';
import { upsertPartnerThunk } from '@pw/redux/thunks/partners';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

// next = '',
// status = '',
// created = 0,
// company_id = '',
// company_name = '',
// company_key = '',
// company_key_hash = '',
// excise_id = '',
// address_1 = '',
// address_2 = '',
// address_3 = '',
// city = '',
// state = '',
// post_code = '',
// country = '',
// id = '',
// path = '',

function PartnerForm({
	partner,
	onCancel = () => {},
	onSubmit = () => {},
	readonly = false,
}) {
	const dispatch = useDispatch();
	const [target, scroll] = useScrollTarget();

	const changeSet = useMemo(
		() => ({
			// Set the editable properties
			company_name: [
				partner?.company_name ?? '',
				yup.string().required('Company Name is required'),
			],
			// company_key: [
			// 	partner?.company_key ?? '',
			// 	yup.string().required('Company Key is required'),
			// ],
			excise_id: [partner?.excise_id ?? '', yup.string()],
			//  address
			line1: [
				partner?.address_1 ?? '',
				yup.string().required('Address is required!'),
			],
			line2: [partner?.address_2 ?? '', yup.string()],
			line3: [partner?.address_3 ?? '', yup.string()],
			city: [partner?.city ?? '', yup.string().required('City is required!')],
			state: [partner?.state ?? '', yup.string()],
			country: [
				partner?.country ?? '',
				yup.string().required('Country is required!'),
			],
			postCode: [
				partner?.post_code ?? '',
				yup.string().required('Post code is required!'),
			],
		}),
		[
			partner?.address_1,
			partner?.address_2,
			partner?.address_3,
			partner?.city,
			partner?.company_name,
			partner?.country,
			partner?.excise_id,
			partner?.post_code,
			partner?.state,
		],
	);

	const handleSubmit = useCallback(
		async (values) => {
			dispatch(
				upsertPartnerThunk({
					...values,
					post_code: values.postCode,
					path: partner?.path,
				}),
			)
				.unwrap()
				.then(onSubmit);
		},
		[dispatch, onSubmit, partner?.path],
	);

	useEffect(() => {
		scroll();
	}, [partner]);

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			readonly={readonly}
		>
			<Stack className='form' spacing='1.2rem' ref={target}>
				<H5>{partner?.path ? 'Update' : 'Create'} Partner</H5>

				<Instructions>
					Create a new partner or update an existing partner.
				</Instructions>

				<Stack className='form' spacing='2rem'>
					<Stack spacing='1.5rem'>
						<FormikTextField
							name='company_name'
							label='Company Name'
							disabled={!!partner?.path}
						/>
						{/*<FormikTextField*/}
						{/*	name='company_key'*/}
						{/*	label='Company PublicKey'*/}
						{/*	InputProps={{*/}
						{/*		endAdornment: partner?.company_key ? (*/}
						{/*			<InputAdornment position='end'>*/}
						{/*				<IconButton*/}
						{/*					aria-label='Copy PublicKey'*/}
						{/*					onClick={() => handleCopy(partner?.company_key)}*/}
						{/*					edge='end'*/}
						{/*				>*/}
						{/*					<ContentCopyIcon />*/}
						{/*				</IconButton>*/}
						{/*			</InputAdornment>*/}
						{/*		) : null,*/}
						{/*	}}*/}
						{/*/>*/}
						<FormikTextField name='excise_id' label='Excise/Tax id' />
					</Stack>

					<Stack spacing='1.5rem'>
						<H5>Organization Address</H5>
						<Address />
					</Stack>
				</Stack>
				<Box
					className='action-buttons'
					display={'flex'}
					justifyContent={'flex-end'}
				>
					<TextButton
						size='small'
						handleClick={onCancel}
						color='secondary'
						label='Cancel'
					/>
					<FilledButton type='submit' size='small'>
						{partner?.path ? 'Update' : 'Create'}
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default PartnerForm;
