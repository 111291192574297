import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikCheckBox,
	FormikDatePicker,
	FormikForm,
	FormikMeasuresField,
	FormikNumberField,
	FormikSelect,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { InventorySelectorV2 } from '@pw/components/InventorySelector';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import TrackedSKUSetup from '@pw/components/SKUSelector/modals/dest/TrackedSKUSetup';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS, SKU_TYPES } from '@pw/consts/sku';
import { useCompanySKUs } from '@pw/redux/containers/User';
import toSKUStorageItem from '@pw/utilities/adapters/toSKUStorageItem';
import { COMP, ID } from '@pw/utilities/comp';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

export function SKUStorageItem({ item, unit }) {
	const { amount } = item;

	return <AmountDisplay label='Allocated' unit={unit} amount={amount} />;
}

function DeliverySKUModal({ item, open, onClose }) {
	const companySkus = useCompanySKUs();

	const { enqueueSnackbar } = useSnackbar();

	console.log('Item', item);

	const {
		sku_id,
		sku_type,
		sku_name,
		entries = [{ amount: null }],
	} = item ?? {};

	// Always load the latest sku state
	const skuItem = companySkus.find((s) => s.sku_id === sku_id);
	const { unit, releases = [], vendors = [], last_number = 0 } = skuItem?.properties ?? {};

	// We expect there to be an entry - which has the specific values we want for delivery..
	const entry = entries[0] ?? {};
	const {
		expiry,
		vendor,
		release,
		total_cost,
		duty_paid = false,
		amount,
		storage = [],
		tracked = [],
		location = { enable: false },
		accounts = { enable: false },
	} = entry;

	const converter = useConverter();

	const hasUnits = useMemo(
		() =>
			![
				SKU_TYPES.TRACKED,
				SKU_TYPES.FINISHED,
				SKU_TYPES.WIP,
				SKU_TYPES.SERVICE,
				SKU_TYPES.CONSUMABLE,
			].includes(sku_type),
		[sku_type],
	);

	console.log('SKU Item', hasUnits, item);

	const isExpiring = useMemo(
		() => [SKU_TYPES.EXPIRING].includes(sku_type),
		[sku_type],
	);
	const isTracked = useMemo(
		() => [SKU_TYPES.TRACKED].includes(sku_type),
		[sku_type],
	);
	const isFinishedGoods = useMemo(
		() => [SKU_TYPES.FINISHED].includes(sku_type),
		[sku_type],
	);
	const isService = useMemo(
		() => [SKU_TYPES.SERVICE].includes(sku_type),
		[sku_type],
	);
	const isGeneral = useMemo(
		() =>
			![SKU_TYPES.TRACKED, SKU_TYPES.WASTE, SKU_TYPES.SERVICE].includes(
				sku_type,
			),
		[sku_type],
	);

	// Any tracked assets
	const [
		trackedAssets,
		initTrackedAssets,
		,
		upsertTrackedAsset,
		removeTrackedAsset,
	] = useItemListManager(ID.asset, COMP.asset, tracked);
	// Storage assets
	const [storageAssets, , , upsertAsset, removeAsset] = useItemListManager(
		ID.asset,
		COMP.asset,
		storage,
	);
	// Specific storage asset being edited
	const [storageAsset, setStorageAsset] = useState(null);

	const count = useMemo(() => {
		console.log('Amount', amount, 'Tracked', trackedAssets.length);
		if (trackedAssets.length > 0) {
			return trackedAssets.length;
		}
		return amount;
	}, [amount, trackedAssets]);

	const [ProgressBar, { setProgress, progressTotal, completion }] = useProgress();

	const fn = useMemo(() => {
		if (isFinishedGoods) {
			return {
				release: [
					release,
					yup.object().shape({
						id: yup.string(),
						name: yup.string(),
						liquid_type: yup.string(),
					}),
				],
				duty_paid: [!!duty_paid, yup.boolean()],
			};
		}
		return {};
	}, [duty_paid, isFinishedGoods]);

	const exp = useMemo(() => {
		if (isExpiring) {
			return {
				expiry: [
					expiry ?? new Date().getTime(),
					yup.number().integer().required('Expiry required!'),
				],
			};
		}
		return {};
	}, [expiry, isExpiring]);

	const releaseOptions = useMemo(
		() => (releases ?? []).map((v) => ({ label: v?.name, value: v })),
		[releases],
	);

	const vendorOptions = useMemo(
		() =>
			(skuItem?.vendors ?? [])
				?.filter(({ vendor }) => vendor?.enable && vendor?.id)
				.map((v) => ({ label: `${v.product}`, value: v.vendor })),
		[skuItem],
	);

	const changeSet = useMemo(
		() => ({
			...(!isService
				? {
						vendor: [
							vendor?.id,
							yup.object().required('Please select a Vendor!'),
						],
					}
				: {}),
			amount: [
				!isTracked && unit && count
					? converter.cx(count, null, unit)
					: count ?? '',
				yup
					.number()
					.positive('Items must be positive!')
					.required('Amount required!'),
			],
			...exp,
			...fn,
			total_cost: [
				total_cost ?? '',
				yup.number().nullable().min(0, 'Must be positive!'),
			],
			...(isTracked
				? {
						location: locationFields(location),
						accounts: assetAccountsFields(accounts),
					}
				: {}),
		}),
		[],
	);

	const handleSubmit = useCallback(
		(values) => {

			// Grab the client information from the SKU, and set that in the storage entry
			// so that we know who owns this stuff..
			let client = undefined;
			if (!!skuItem.client?.enable) {
				client = {
					id: skuItem.client?.id,
					name: skuItem.client?.name,
					type: skuItem.client?.type,
				};
			}			
			const useVendor = skuItem?.vendors.find((v) => v.vendor.id === values.vendor?.id);	
			let vendor = undefined;
			if (useVendor) {
				vendor = {
					id: useVendor.vendor.id,
					name: useVendor.vendor.name,
					public_name: useVendor.vendor.public_name,
					type: useVendor.vendor.type,
					product: useVendor.product,
				};
			}			

			const updatedStorage = (storageAssets ?? []).map((s) => ({
				...s,
				client,
				vendor,
				release: values.release,
				duty_paid: values.duty_paid,
			}));

			const updatedEntry = {
				...entry,
				vendor,
				release: values?.release,
				client,
				total_cost: values?.total_cost,
				expiry: values?.expiry,
				duty_paid: values?.duty_paid,
				amount: hasUnits
					? converter.cx(values.amount, unit)
					: tracked.length > 0
						? tracked.length
						: values.amount,
				storage: updatedStorage,
				tracked: trackedAssets,
				location: values?.location,
				accounts: values?.accounts,
			};

			console.log('Updated Entry', hasUnits, updatedEntry);

			let processed = item?.processed ?? INVENTORY_STATUS.PENDING;
			if (storageAssets && storageAssets.length > 0) {
				processed =
					storageAssets.find((i) => i.processed !== ASSET_PROCESSED_STATUS.CONFIRMED)
					? INVENTORY_STATUS.PENDING : INVENTORY_STATUS.CONFIRMED;
			}

			const sku = {
				...item,
				processed,
				last_number: Number(last_number) + trackedAssets.length,
				entries: [updatedEntry],
			};
			console.log('SKU', sku);

			onClose(sku);
		},
		[skuItem, item, vendors, entry, storageAssets, trackedAssets],
	);

	useEffect(
		() => {
			const totalAllocated = storageAssets.reduce(
				(v, i) =>
					Number(v) +
					Number(unit ? converter.cx(i.amount, null, unit) : i.amount * 1.0),
				0,
			);
			console.log('Total allocated', totalAllocated);
			setProgress(totalAllocated);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[storageAssets, unit],
	);

	// Need to adapt the SKU to meet the SKUItem/SKUEntryItem/SKUStorage structure
	// As this is a delivery, we'll add one basic entry here
	const handleAdd = (asset) => {
		if (asset?.asset_status === ASSET_STATUS.DEFECTED) {
			enqueueSnackbar(`Defective assets cannot be selected!`, {
				variant: 'error',
			});
			return;
		}
		setStorageAsset(toSKUStorageItem(asset));
	};

	const display = (value) => (
		<SKUStorageItem
			item={value}
			key={value.asset_id}
			unit={hasUnits ? unit : null}
		/>
	);

	const filter = useMemo(
		() => ({
			asset_types: [ASSET_TYPES.pallet, ASSET_TYPES.container],
		}),
		[],
	);

	return (
		<>
			<ModalWithClose open={open} onClose={() => onClose()} title={`${sku_name} Delivery`}>
				<FormikForm
					changeSet={changeSet}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					<Stack spacing='2rem'>
						{!isService && (
							<FormikSelect
								label='Vendor'
								name='vendor'
								options={vendorOptions}
								fullWidth
							/>
						)}
						{isExpiring && (
							<FormikDatePicker label='Expiry' name='expiry' fullWidth />
						)}
						{isFinishedGoods && (
							<FormikCheckBox label='Duty Paid' name='duty_paid' />
						)}
						<FlexBox>
							<FormikMeasuresField
								label={hasUnits ? 'Amount' : 'Items'}
								name='amount'
								unit={hasUnits ? unit : null}
								fullWidth
							/>
							{!isService && (
								<FormikNumberField
									label='Total Cost'
									name='total_cost'
									fullWidth
								/>
							)}
						</FlexBox>

						{isGeneral && (
							<>
								<Instructions>
									Please select the storage for the contents of this
									delivery.
								</Instructions>

								<ProgressBar name='amount' label='Amount' />

								<InventorySelectorV2
									title='Storage Allocation'
									types={filter}
									onAdd={handleAdd}
									assets={[
										storageAssets,
										setStorageAsset,
										removeAsset,
										display,
									]}
								/>
							</>
						)}
						{isTracked && (
							<>
								<Location name='location' />
								<AssetAccounts />
								<TrackedSKUSetup
									sku={item}
									field='amount'
									assets={[
										trackedAssets,
										initTrackedAssets,
										upsertTrackedAsset,
										removeTrackedAsset,
									]}
								/>
							</>
						)}
						<Errors />

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton
								type='submit'
								size='small'
								disabled={isTracked && trackedAssets.length === 0}
							>
								Save
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</ModalWithClose>

			{!!storageAsset && (
				<StorageSetupModal
					open={!!storageAsset}
					unit={hasUnits ? unit : null}
					item={storageAsset}
					onClose={(v) => {
						if (v) {
							upsertAsset(v);
						}
						setStorageAsset(null);
					}}
					left={progressTotal - completion}
				/>
			)}
		</>
	);
}

export default DeliverySKUModal;
