import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import LiquidProperties, {
	liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import convertProperties from '@pw/utilities/convertProperties';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function Properties() {
	return (
		<FormikProvider path='properties'>
			<LiquidProperties name='liquid' useWeight />
		</FormikProvider>
	);
}

function propertyFields(fields = {}, converter) {
	const { liquid = {} } = fields;

	return {
		liquid: liquidPropertyFields(liquid, converter, true, false),
	};
}

function RegaugeForm(props) {
	let [searchParams] = useSearchParams();
	const [fetchAsset] = useAssetLazyQuery();
	const asset = searchParams.get('asset');

	const converter = useConverter();

	const [
		[],
		[sourceAssets, initSourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Assets',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.filling_tank,
			],
		},
	});

	const [
		[destinationSkus],
		[],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Services',
		filter: {
			sku_types: [SKU_TYPES.SERVICE],
		},
		SKUModal: DestinationSKUModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: propertyFields(initialValues?.properties, converter),
			client: clientFields(initialValues?.client),
		}),
		[converter],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({ ...entity, skus: entity?.sku_destinations });
		},
		[initSources, initDestinations],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			const properties = convertProperties(entity?.properties ?? {}, converter);

			return {
				...entity,
				sources: sourceAssets,
				sku_destinations: destinationSkus,
				properties,
			};
		},
		[destinationSkus, sourceAssets],
	);

	useEffect(() => {
		if (asset) {
			fetchAsset(asset).then((a) => {
				debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
			});
		}
	}, [asset, initSourceAssets, fetchAsset]);

	return (
		<>
			<RequestForm
				requestLabel='Regauge'
				requestType={REQUEST_TYPES.regauge}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />
				<DestinationInventory />

				<Properties />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
		</>
	);
}

export default withAppLayout(RegaugeForm, { title: 'Regauge' });
