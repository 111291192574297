import { Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { clientFields } from '@pw/components/Client/clientFields';
import {
	FormikCheckBox,
	FormikDatePicker,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DeliveryDestinationAssetModal from '@pw/components/SKUSelector/modals/DeliveryDestinationAssetModal';
import DeliverySKUModal from '@pw/components/SKUSelector/modals/DeliverySKUModal';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCompanySKUs } from '@pw/redux/containers/User/hooks';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

function Properties() {
	const { values, readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path='properties'>
				<Stack spacing={2}>
					<H5>Invoice Details</H5>
					<FlexBox>
						<FormikTextField
							name='invoice_number'
							label='Invoice Number'
							fullWidth
						/>
						<FormikDatePicker
							name='invoice_date'
							label='Invoice Date'
							fullWidth
						/>
					</FlexBox>
					<FlexBox>
						<FormikTextField
							name='invoice_amount'
							label='Total Cost'
							fullWidth
						/>
						<FormikTextField name='tax_amount' label='Tax' fullWidth />
					</FlexBox>
					<Stack>
						<FormikCheckBox name='paid' label='Paid' />
						<Collapse in={Boolean(values?.properties?.paid)}>
							<FormikTextField
								name='payment_confirmation'
								label='Payment confirmation'
								fullWidth
							/>
						</Collapse>
					</Stack>
				</Stack>
			</FormikProvider>
		)
	);
}

function propertyFields(fields = {}) {
	const {
		invoice_number = '',
		invoice_date = Date.now(),
		invoice_amount = '',
		tax_amount = '',
		paid = false,
		payment_confirmation = '',
	} = fields;

	return {
		invoice_number: [invoice_number, yup.string()],
		invoice_date: [invoice_date, yup.number()],
		invoice_amount: [invoice_amount, yup.number()],
		tax_amount: [tax_amount, yup.number()],
		paid: [paid, yup.bool()],
		payment_confirmation: [payment_confirmation, yup.string()],
	};
}

function DeliveryForm(props) {
	const location = useLocation();

	const [, submitForm] = useFormSubmissionHook();
	const { sku_id } = location.state ?? {};

	const skus = useCompanySKUs();

	const [
		[destinationSkus],
		[destinationAssets],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Material & Services',
		filter: {
			sku_types: [
				SKU_TYPES.TRACKED,
				SKU_TYPES.RAW,
				SKU_TYPES.EXPIRING,
				SKU_TYPES.CONSUMABLE,
				SKU_TYPES.SERVICE,
				SKU_TYPES.FINISHED,
			],
		},
		submitForm,
		assetAdapter: toTaggedAsset,
		assetFilter: (asset) =>
			[
				ASSET_TYPES.pallet,
				ASSET_TYPES.ibc,
				ASSET_TYPES.cask,
				ASSET_TYPES.tanker,
				ASSET_TYPES.container,
			].includes(asset.asset_type),
		SKUModal: DeliverySKUModal,
		AssetModal: DeliveryDestinationAssetModal,
		parentAssetSupport: true,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: propertyFields(initialValues?.properties),
			client: clientFields(initialValues.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			const destSkus = entity?.sku_destinations ?? [];

			if (sku_id) {
				const exists = destSkus.find((s) => s.sku_id === sku_id);
				if (!exists) {
					const selectedSku = skus.find((s) => s.sku_id === sku_id);
					if (selectedSku) {
						destSkus.push(selectedSku);
					}
				}
			}

			initDestinations({
				...entity,
				assets: entity?.destinations,
				skus: destSkus,
			});
		},
		[sku_id, skus, initDestinations],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_destinations: destinationSkus,
			destinations: destinationAssets,
		}),
		[destinationSkus, destinationAssets],
	);

	return (
		<>
			<RequestForm
				requestLabel='Delivery'
				requestType={REQUEST_TYPES.delivery}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<DestinationInventory />

				<Properties />
				{/*<FormSubmitter />*/}
			</RequestForm>

			<DestinationModals />
		</>
	);
}

export default withAppLayout(DeliveryForm, { title: 'Deliveries' });
