import { UNIT_FORMAT_TYPES } from '@pw/consts/company';
import { UNIT, UNITS } from '@pw/consts/units';
import { useSelector } from 'react-redux';
import {
	activeCompany,
	activeUser,
	selectAccountChain,
	selectAccountEmail,
	selectAccountId,
	selectAccountIdentity,
	selectAccountLastAccess,
	selectAccountLocation,
	selectAccountName,
	selectAccountPerms,
	selectAccountPlatform,
	selectAccountStatus,
	selectAccountUsername,
	selectAllCompanies,
	selectBaseUnits,
	selectCompanyCC,
	selectCompanyFacilities,
	selectCompanyId,
	selectCompanyLiquidTypes,
	selectCompanyName,
	selectCompanyOwners,
	selectCompanyPartners,
	selectCompanyPermissions,
	selectCompanyRoles,
	selectCompanySensors,
	selectCompanySKUs,
	selectCompanyTeams,
	selectCompanyType,
	selectCompanyUsers,
	selectIsAccountActive,
	selectIsBrokerCompany,
	selectIsUsUser,
	selectPermissions,
	selectUnitFormat,
} from './selectors';

export const useAccount = () => useSelector(activeUser);
export const useAccountId = () => useSelector(selectAccountId);
export const useAccountIdentity = () => useSelector(selectAccountIdentity);
export const useAccountName = () => useSelector(selectAccountName);
export const useAccountUsername = () => useSelector(selectAccountUsername);
export const useAccountEmail = () => useSelector(selectAccountEmail);
export const useAccountLocation = () => useSelector(selectAccountLocation);
export const useAccountLastAccess = () => useSelector(selectAccountLastAccess);
export const useAccountStatus = () => useSelector(selectAccountStatus);
export const useAccountPlatform = () => useSelector(selectAccountPlatform);
export const useAccountChain = () => useSelector(selectAccountChain);
export const useIsAccountActive = () => useSelector(selectIsAccountActive);
export const useAccountPermissions = () => useSelector(selectPermissions);
export const useAccountPerms = () => useSelector(selectAccountPerms);

export const useCurrentCompany = () => useSelector(activeCompany);
export const useCompanyCountry = () => useSelector(selectCompanyCC);
export const useCompanyId = () => useSelector(selectCompanyId);
export const useCompanyName = () => useSelector(selectCompanyName);
export const useCompanyUsers = () => useSelector(selectCompanyUsers);
export const useAllCompanies = () => useSelector(selectAllCompanies);
export const useCompanySKUs = () => useSelector(selectCompanySKUs);
export const useCompanyLiquidTypes = () =>
	useSelector(selectCompanyLiquidTypes);
export const useCompanySensors = () => useSelector(selectCompanySensors);
export const useCompanyFacilities = () => useSelector(selectCompanyFacilities);
export const useCompanyPartners = () => useSelector(selectCompanyPartners);
export const useCompanyOwners = () => useSelector(selectCompanyOwners);
export const useCompanyRoles = () => useSelector(selectCompanyRoles);
export const useCompanyTeams = () => useSelector(selectCompanyTeams);
export const useIsBrokerCompany = () => useSelector(selectIsBrokerCompany);
export const useCompanyType = () => useSelector(selectCompanyType);
export const useCompanyPermissions = () =>
	useSelector(selectCompanyPermissions);

export const useIsUsUser = () => useSelector(selectIsUsUser);

export const useIsUnitFormatImperial = () =>
	UNIT_FORMAT_TYPES.Imperial === useSelector(selectUnitFormat);

export const usePreferredUnits = () => {
	const state = useSelector(selectBaseUnits);
	const imperial = useIsUnitFormatImperial();

	return {
		[UNIT.LIQ]: state?.[UNIT.LIQ] || (imperial ? UNITS.gallons : UNITS.liters),
		[UNIT.MAS]:
			state?.[UNIT.MAS] || (imperial ? UNITS.pounds : UNITS.kilograms),
		[UNIT.ALC]: state?.[UNIT.ALC] || (imperial ? UNITS.proof : UNITS.abv),
		[UNIT.TMP]:
			state?.[UNIT.TMP] || (imperial ? UNITS.fahrenheit : UNITS.celsius),
		[UNIT.TIM]: state?.[UNIT.TIM] || UNITS.days,
		[UNIT.BL]: state?.[UNIT.BL] || (imperial ? UNITS.gallons : UNITS.liters),
		[UNIT.LA]:
			state?.[UNIT.LA] || (imperial ? UNITS.proof_gallons : UNITS.liters),
	};
};
