const calculateSourcesGains = (assets) => {
	let storageBLGain = 0;
	let storageLAGain = 0;
	let totalBL = 0;
	let totalLA = 0;
	let isImported = false;
	let isDutyPaid = false;

	if (!assets.length) {
		return [0, 0, 0, 0, false, false];
	}
	assets.forEach((asset) => {
		const { properties = {} } = asset;
		const { liquid = {}, imported = false, duty_paid = false } = properties;
		const { level = {} } = liquid;
		const {
			bl = 0,
			expected_bl = 0,
			updated_abv = 0,
			updated_tcf = 1,
			updated_bl = 0,
		} = level;

		isImported = imported;
		isDutyPaid = duty_paid;

		const actualBL = Number(bl) - Number(updated_bl);
		const bulkGain = Number(expected_bl) - actualBL;

		storageBLGain += bulkGain;
		storageLAGain +=
			bulkGain * ((Number(updated_abv) * Number(updated_tcf)) / 100);

		totalBL += Number(expected_bl);
		totalLA +=
			Number(expected_bl) * ((Number(updated_abv) * Number(updated_tcf)) / 100);
	});

	console.log('Totals', storageBLGain, storageLAGain, totalBL, totalLA);
	return [
		storageBLGain,
		storageLAGain,
		totalBL,
		totalLA,
		isImported,
		isDutyPaid,
	];
};

const calculateDestinationsGains = (assets) => {
	let totalBL = 0;
	let totalLA = 0;

	if (!assets.length) {
		return [0, 0];
	}
	assets.forEach((asset) => {
		const { properties = {} } = asset;
		const { liquid = {} } = properties;
		const { level = {} } = liquid;
		const { filled_bl = 0, updated_abv = 0, updated_tcf = 0 } = level;

		const absoluteBL = Math.abs(Number(filled_bl));
		totalBL += absoluteBL;
		totalLA += absoluteBL * ((Number(updated_abv) * Number(updated_tcf)) / 100);
	});

	return [totalBL, totalLA];
};

export const summarizeTransferLiquids = (sources, destinations) => {
	const [
		sourceStorageBLGain,
		sourceStorageLAGain,
		sourceTotalBL,
		sourceTotalLA,
		isImported,
		isDutyPaid,
	] = calculateSourcesGains(sources);

	const [destTotalBL, destTotalLA] = calculateDestinationsGains(destinations);

	const operationsBLGain = destTotalBL - sourceTotalBL;
	const operationsLAGain = destTotalLA - sourceTotalLA;

	return {
		s_bl: sourceStorageBLGain,
		s_la: sourceStorageLAGain,
		o_bl: operationsBLGain,
		o_la: operationsLAGain,
		imported: isImported,
		duty_paid: isDutyPaid,
	};
};
