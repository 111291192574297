import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/sku`;

export const createSKU = async (sku, headers = {}) =>
	fetchPost(BASE, headers, sku);

export const cloneSKU = async (sku, headers = {}) =>
	fetchPost(`${BASE}/clone`, headers, sku);

export const getSKU = async (params, headers = {}) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);

export const getSKUEntries = async (request, headers = {}) =>
	fetchPost(`${BASE}/entries`, headers, request);

export const getSKUList = async (params = {}, headers = {}) =>
	fetchGet(`${BASE}/list${objectToQueryString(params)}`, headers);

export const getSKUs = async (headers = {}) =>
	fetchGet(`${BASE}/list`, headers);

export const getSKUAssets = async (params, headers = {}) =>
	fetchPost(`${BASE}/assets`, headers, params);
