import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import RestockAssetModal from '@pw/components/SKUSelector/modals/RestockAssetModal';
import RestockSKU from '@pw/components/SKUSelector/modals/RestockSKUModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { useCallback, useEffect, useMemo } from 'react';

function RestockForm(props) {
	const [
		[sourceSkus],
		[sourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Source Inventory',
		assetFilter: () => true,
		filter: {
			sku_types: [
				SKU_TYPES.RAW,
				SKU_TYPES.EXPIRING,
				SKU_TYPES.CONSUMABLE,
				SKU_TYPES.FINISHED,
			],
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.pallet,
				ASSET_TYPES.container,
			],
		},
		SKUModal: SourceSKUModal,
		AssetModal: RestockAssetModal,
	});

	const [
		[destinationSkus, , , upsertDestinationSkus],
		[destinationAssets],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useSourceInventoryHook({
		title: 'Restocked Inventory & Services',
		filter: {
			sku_types: [
				SKU_TYPES.RAW,
				SKU_TYPES.EXPIRING,
				SKU_TYPES.CONSUMABLE,
				SKU_TYPES.FINISHED,
			],
		},
		SKUModal: RestockSKU,
	});

	useEffect(() => {
		console.log('sku changed', sourceSkus);
		const skus = sourceSkus.filter(
			(s) => !destinationSkus.some((ds) => ds.sku_id === s.sku_id),
		);
		upsertDestinationSkus([
			...skus.map((s) => ({
				...s,
				entries: [{ amount: s.entries[0]?.amount || 0 }],
			})),
		]);
	}, [sourceSkus]);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({
				...entity,
				assets: entity?.destinations,
				skus: entity?.sku_destinations,
			});
		},
		[initSources, initDestinations],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
			destinations: destinationAssets,
			sku_destinations: destinationSkus,
		}),
		[sourceSkus, sourceAssets, destinationAssets, destinationSkus],
	);

	return (
		<>
			<RequestForm
				requestLabel='Restock'
				requestType={REQUEST_TYPES.restock}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />
				<DestinationInventory />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
		</>
	);
}

export default withAppLayout(RestockForm, { title: 'Restock' });
