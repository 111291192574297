import Stack from '@mui/material/Stack';
import {
	FormikNumberField, FormikSelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import DependentSKUModal from '@pw/components/SKUSelector/modals/DependentSKUModal';
import { H5 } from '@pw/components/Typography';
import Releases from '@pw/components/admin/Releases';
import SCCodes from '@pw/components/admin/SCCodes';
import SKUForm from '@pw/components/admin/SKUForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import { FINISHED_GOODS_TYPE_OPTIONS, SKU_TYPES } from '@pw/consts/sku';
import {
	ABV_UNIT_OPTIONS,
	LIQUID_UNIT_OPTIONS,
	MASS_UNIT_OPTIONS,
	UNITS_OPTIONS,
} from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsUsUser } from '@pw/redux/containers/User/hooks';
import debounce from '@pw/utilities/debounce';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useUSCompliance from '@pw/compliance/cc/useUSCompliance';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';

function propertyFields(fields = {}) {
	const {
		type = '0',
		lot_code = '',
		count = 1,
		size = {},
		gross_weight = {},
		net_weight = {},
		abv = {},

		prefix = '',
		nabca = '',
		suffix = '',
		upc_code = '',
		scc_code = '',
		sc_codes = [],

		releases = [],
		skus = [],
	} = fields;

	return {
		type: [type, yup.string().required('Type is required')],
		lot_code: [lot_code, yup.string()],

		count: [count, yup.number()],
		size: unitField(size),
		abv: unitField(abv),

		gross_weight: unitField(gross_weight),
		net_weight: unitField(net_weight),

		prefix: [prefix, yup.string().min(3, 'Prefix must be 3 characters').max(3, 'Prefix must be 3 characters')],
		nabca: [nabca, yup.string()],
		suffix: [suffix, yup.string().min(2, 'Suffix must be 2 characters').max(2, 'Prefix must be 3 characters')],

		upc_code: [upc_code, yup.string()],
		scc_code: [scc_code, yup.string()],
		nc_code: [scc_code, yup.string()],

		sc_codes: [
			sc_codes,
			yup
				.array()
				.of(yup.object().shape({ state: yup.string(), code: yup.string() })),
		],

		releases: [releases,
			yup
				.array()
				.of(yup.object())
		],

		skus: [skus, yup.array().of(yup.object())],
	};
}

function NABCASettings() {
	const liquidTypes = useLiquidTypes();
	const { setFieldValue, values } = useContext(FormikContext);

	// We can somewaht automate the code fields..
	const compliance = useUSCompliance();
	const size = values?.['size'];
	const releases = values?.['releases'] ?? [];

	useEffect(() => {
		if (size && size.value && size.unit) {
			const cc = compliance?.getNABCASuffix(size);
			debounce(() => setFieldValue('suffix', cc), 25);
		}
	}, [size]);

	useEffect(() => {
		const lastRelease = releases[releases.length - 1];
		if (lastRelease) {
			const liquid_type = lastRelease?.liquid_type;
			const liquid = liquidTypes.find((l) => l.value === liquid_type);
			debounce(() => setFieldValue('prefix', liquid?.nabca_prefix), 25);
		}
	}, [releases]);

	return (
		<Stack spacing='1.5rem'>
			<H5>NABCA Settings</H5>
			<FlexBox>
				<FormikNumberField label='Prefix' name='prefix' fullWidth maxLength={3} minLength={3} />
				<FormikNumberField label='NABCA' name='nabca' fullWidth />
				<FormikNumberField label='Suffix' name='suffix' fullWidth />
			</FlexBox>
			<FlexBox>
				<FormikNumberField label='UPCCode' name='upc_code' fullWidth />
				<FormikNumberField label='SCCCode' name='scc_code' fullWidth />
			</FlexBox>
			<SCCodes />
		</Stack>
	);
}

function PackProperties() {
	const { setFieldValue, values } = useContext(FormikContext);

	const dependentSkuProps = useMemo(
		() => ({
			title: 'BOM',
			filter: {
				sku_types: [SKU_TYPES.CONSUMABLE, SKU_TYPES.FINISHED],
			},
			initialSkus: values?.skus,
			SKUModal: DependentSKUModal,
		}),
		[values?.skus],
	);

	const [[dependentSkus], , , DependentInventory, DependentModals] =
		useInventorySelectorHook(dependentSkuProps);

	useEffect(() => {
		console.log('Updating SKUs', dependentSkus);
		debounce(() => setFieldValue('skus', dependentSkus), 25);
	}, [dependentSkus]);

	return (
		<>
			<DependentInventory />
			<DependentModals />
		</>
	);
}

function BottleProperties() {
	const { setFieldValue, values } = useContext(FormikContext);

	const dependentSkuProps = useMemo(
		() => ({
			title: 'BOM',
			filter: {
				sku_types: [SKU_TYPES.CONSUMABLE],
			},
			initialSkus: values?.skus,
			SKUModal: DependentSKUModal,
		}),
		[values?.skus],
	);

	const [[dependentSkus], , , DependentInventory, DependentModals] =
		useInventorySelectorHook(dependentSkuProps);

	useEffect(() => {
		console.log('Updating SKUs', dependentSkus);
		debounce(() => setFieldValue('skus', dependentSkus), 25);
	}, [dependentSkus]);

	return (
		<>
			<FlexBox>
				<FormikNumberField label='Bottles per Case' name='count' fullWidth />
				<FormikUnitField
					label='Bottle Size'
					name='size'
					options={LIQUID_UNIT_OPTIONS}
					fullWidth
				/>
			</FlexBox>
			<FormikUnitField label='Strength' name='abv' options={ABV_UNIT_OPTIONS} />

			<Releases />

			<DependentInventory />
			<DependentModals description="This is deprecated, please use the BOM in the release" />
		</>
	);
}

function ItemProperties() {
	const { values } = useContext(FormikContext);

	const type = useMemo(() => values?.['type'], [values]);

	return (
		<>
			{type === '0' && <BottleProperties />}
			{type === '1' && <PackProperties />}
		</>
	);
}

function PropertiesImpl() {
	const isUsUser = useIsUsUser();

	return (
		<Stack spacing={3}>
			<H5>Properties</H5>
			<FormikSelect name='type' label='Type' options={FINISHED_GOODS_TYPE_OPTIONS} />
			<ItemProperties />

			<H5>Shipping Weight</H5>
			<FlexBox>
				<FormikUnitField
					label='Gross Weight'
					name='gross_weight'
					options={MASS_UNIT_OPTIONS}
					fullWidth
				/>
				<FormikUnitField
					label='Net Weight'
					name='net_weight'
					options={MASS_UNIT_OPTIONS}
					fullWidth
				/>
			</FlexBox>

			{isUsUser && (
				<NABCASettings />
			)}
			{!isUsUser && (
				<Stack spacing='1.5rem'>
					<H5>Identifier</H5>
					<FormikTextField label='Lot Code' name='lot_code' fullWidth />
				</Stack>
			)}
		</Stack>
	);
}

function Properties(props) {
	return (
		<FormikProvider path='properties'>
			<PropertiesImpl {...props} />
		</FormikProvider>
	);
}

function SKUPage(props) {
	const changeSetGenerator = (initialValues) => ({
		...skuSettingsFields(initialValues, SKU_TYPES.FINISHED),
		properties: propertyFields(initialValues.properties),
	});

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Finished Good'
			skuType={SKU_TYPES.FINISHED}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</SKUForm>
	);
}

export default withAppLayout(SKUPage, {
	title: 'Finished Good SKUs',
});

