import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikMeasuresField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { InventorySelectorV2 } from '@pw/components/InventorySelector';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS, SKU_TYPES } from '@pw/consts/sku';
import toSKUStorageItem from '@pw/utilities/adapters/toSKUStorageItem';
import { COMP, ID } from '@pw/utilities/comp';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { SKUStorageItem } from './DeliverySKUModal';
import StorageSetupModal from './StorageSetup';

function RestockSKU({ item, onClose }) {
	const { enqueueSnackbar } = useSnackbar();
	const [storageAsset, setStorageAsset] = useState(null);
	const {
		sku_name,
		sku_description,
		sku_type,
		entries = [{ amount: null }],
		properties,
	} = item ?? {};

	const { unit } = properties;
	const entry = entries[0];
	const { amount, storage = [] } = entry;
	const [storageAssets, , , upsertAsset, removeAsset] = useItemListManager(
		ID.asset,
		COMP.asset,
		storage,
	);
	const [ProgressBar, { setProgress }] = useProgress();
	const hasUnits = useMemo(
		() =>
			![
				SKU_TYPES.TRACKED,
				SKU_TYPES.FINISHED,
				SKU_TYPES.WIP,
				SKU_TYPES.SERVICE,
				SKU_TYPES.CONSUMABLE,
			].includes(sku_type),
		[sku_type],
	);
	const changeSet = useMemo(
		() => ({
			amount: [
				amount ? amount : '',
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
		}),
		[amount],
	);

	const converter = useConverter();

	useEffect(
		() => {
			const totalAllocated = storageAssets.reduce(
				(v, i) =>
					Number(v) + Number(unit ? converter.cx(i.amount, null, unit) : i.amount * 1.0),
				0,
			);
			console.log('Total allocated', totalAllocated);
			setProgress(totalAllocated);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[converter, storageAssets, unit],
	);

	const handleSubmit = (values) => {
		try {
			const updatedEntry = {
				...entry,
				vendor: values?.vendor,
				total_cost: values?.total_cost,
				expiry: values?.expiry,
				duty_paid: values?.duty_paid,
				amount: hasUnits ? converter.cx(values.amount, unit) : values.amount,
				storage: storageAssets,
			};

			console.log('Updated Entry', hasUnits, updatedEntry);

			let processed = item?.processed ?? INVENTORY_STATUS.PENDING;
			if (storageAssets && storageAssets.length > 0) {
				processed = INVENTORY_STATUS.CONFIRMED;
				storageAssets.forEach((i) => {
					i.duty_paid = values?.duty_paid;
					if (i.processed !== ASSET_PROCESSED_STATUS.CONFIRMED) {
						processed = INVENTORY_STATUS.PENDING;
					}
				});
			}

			const sku = {
				...item,
				processed,
				entries: [updatedEntry],
			};
			console.log('SKU', sku);

			onClose(sku);
		} catch (err) {
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	const handleAdd = (asset) => {
		if (asset?.asset_status === ASSET_STATUS.DEFECTED) {
			enqueueSnackbar(`Defective assets cannot be selected!`, {
				variant: 'error',
			});
			return;
		}
		setStorageAsset(toSKUStorageItem(asset));
	};

	const filter = useMemo(
		() => ({
			asset_types: [ASSET_TYPES.pallet, ASSET_TYPES.container],
		}),
		[],
	);

	const display = (value) => (
		<SKUStorageItem item={value} key={value.asset_id} unit={null} />
	);

	return (
		<>
			<ModalWithClose open={open} onClose={() => onClose()} title={sku_name}>
				<Instructions>{sku_description}</Instructions>

				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack spacing={3}>
						<FormikMeasuresField
							label='Amount'
							name='amount'
							unit={hasUnits ? unit : null}
							fullWidth
						/>
						<Stack>
							<Instructions>Please select the storage</Instructions>
							<ProgressBar name='amount' label='Amount' />
						</Stack>

						<InventorySelectorV2
							title='Pallet'
							types={filter}
							onAdd={handleAdd}
							assets={[
								storageAssets,
								setStorageAsset,
								removeAsset,
								display,
							]}
						/>

						<AlertView />

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton
								type='submit'
								size='small'
								disabled={storageAssets.length === 0}
							>
								Save
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</ModalWithClose>

			{!!storageAsset && (
				<StorageSetupModal
					open={!!storageAsset}
					unit={hasUnits ? unit : null}
					item={storageAsset}
					onClose={(v) => {
						if (v) {
							upsertAsset(v);
						}
						setStorageAsset(null);
					}}
				/>
			)}
		</>
	);
}

export default RestockSKU;
