import {
	AccessTime,
	AccountCircle,
	AddCircle,
	Archive,
	Cancel,
	CheckCircle,
	CopyAllOutlined,
	Delete,
	Done,
	Download,
	FactCheck,
	ImportantDevices,
	PendingActionsTwoTone,
	Print,
} from '@mui/icons-material';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AssigneeProps from '@pw/components/AssigneeProps';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import CreatorProps from '@pw/components/CreatorProps';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import RequestQRCode from '@pw/components/QRImage/RequestQRCode';
import DeliveryModal from '@pw/components/SKUSelector/modals/import/DeliveryModal';
import ImportModal from '@pw/components/SKUSelector/modals/import/ImportModal';
import ImportSKUModal from '@pw/components/SKUSelector/modals/import/ImportSKUModal';
import TaskModal from '@pw/components/Tasks/TaskModal';
import { Body1, H5, Overline } from '@pw/components/Typography';
import { Delivery } from '@pw/components/icons';
import { REQUEST_EDIT_URL, REQUEST_STATUS, REQUEST_TYPES, REQUEST_TYPES_REVERSE } from '@pw/consts/requests';
import {
	useAccountPermissions,
	useCompanySKUs, useCompanyUsers,
	useCurrentCompany,
	useIsAccountActive
} from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import { toTasksCardEntity } from '@pw/utilities/format';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import printElement from '@pw/utilities/printElement';
import { useCallback, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import RequestSummaryHTML from './RequestSummaryHTML';

function RequestSummary({ entity, refresh }) {
	const navigate = useNavigate();
	const company = useCurrentCompany();
	const converter = useConverter();

	const { company_id, request_type, request_status, sku_sources, sources } =
		entity;

	const { readonly } = useReadOnly(entity);
	const sameCompany = useCheckCompany(entity);
	const userIsActive = useIsAccountActive();
	const users = useCompanyUsers();

	const printRef = useRef();
	const existingSkus = useCompanySKUs();

	const [importSkuList, setImportSkuList] = useState(null);
	const [mappedSkuList, setMappedSkuList] = useState(null);
	const [deliverySkus, setDeliverySkus] = useState(null);

	const showImport = useMemo(
		() =>
			!sameCompany &&
			userIsActive &&
			REQUEST_TYPES.ship === request_type &&
			REQUEST_STATUS.DONE === request_status,
		[request_status, request_type, sameCompany, userIsActive],
	);

	const showDownload = useMemo(
		() =>
			sameCompany &&
			userIsActive &&
			REQUEST_TYPES.duty_submission === request_type,
		[request_type, sameCompany, userIsActive],
	);

	const showDelivery = useMemo(
		() =>
			sameCompany &&
			userIsActive &&
			REQUEST_TYPES.purchase_order === request_type &&
			REQUEST_STATUS.DONE === request_status,
		[request_type, sameCompany, userIsActive],
	);

	const showActions = useMemo(
		() => userIsActive && !readonly,
		[readonly, userIsActive],
	);

	const shipment = useMemo(() => {
		return entity?.request_type === REQUEST_TYPES.ship && ![REQUEST_STATUS.CANCELLED, REQUEST_STATUS.REJECTED].includes(entity?.request_status);
	}, [entity]);

	const { canApprove = false, assignTask = false } = useAccountPermissions();

	const [actionKey, setActionKey] = useState(null);

	const requestCards = useMemo(() => {
		const type = REQUEST_TYPES_REVERSE[request_type];
		return toTasksCardEntity(entity, type);
	}, [entity, request_type]);

	const handlePrint = (ref) => {
		let element;
		if (entity?.request_type !== REQUEST_TYPES.ship) {
			element = ref?.current?.innerHTML;
		} else {
			element = renderToString(
				<RequestSummaryHTML
					entity={entity}
					converter={converter}
					skus={existingSkus}
				/>,
			);
		}
		printElement({
			element,
			styles: `
    * { 
      padding: 0; 
      margin: 0; 
    } 
    
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    
    tr:nth-of-type(even) {
      background-color: #dddddd;
    }`,
		});
	};

	const handleNew = () => {
		const route = REQUEST_EDIT_URL?.[request_type];
		console.log('Route', request_type, route);
		navigate(route, { replace: true });
	};

	const handleClone = () => {
		const route = REQUEST_EDIT_URL?.[request_type];
		const str = JSON.stringify({
			...entity,
			request_status: REQUEST_STATUS.PENDING,
		});
		const base = Buffer.from(str).toString('base64');

		navigate(`${route}?clone=${base}`, { replace: true });
	};

	const handleImport = useCallback(() => {
		// Need to check this request to make sure we have all the skus from this request
		// There are several places where there are SKUs
		const skus = {};
		// 1. Check the SKU's themselves..
		sku_sources?.forEach((sku) => {
			if (!skus.hasOwnProperty(sku.sku_id)) {
				skus[sku.sku_id] = {
					sku_id: sku.sku_id,
					sku_name: sku.sku_name,
					sku_type: sku.sku_type,
					sku_description: sku.sku_description,
				};
			}
		});
		// 2. Check the sources
		sources?.forEach((asset) => {
			const {
				properties = {}, // this contains nested skus
			} = asset;
			const { sku_id: assetSkuId, sku: assetSku, liquid = {} } = properties;
			const { sku_id: liquidSkuId, sku: liquidSku } = liquid;
			// ignore the sales skus
			if (assetSkuId && assetSku) {
				if (!skus.hasOwnProperty(assetSkuId)) {
					skus[assetSkuId] = {
						sku_id: assetSkuId,
						sku_name: assetSku.sku_name,
						sku_type: assetSku.sku_type,
						sku_description: assetSku.sku_description,
					};
				}
			}

			if (liquidSkuId && liquidSku) {
				if (!skus.hasOwnProperty(liquidSkuId)) {
					skus[liquidSkuId] = {
						sku_id: liquidSkuId,
						sku_name: liquidSku.sku_name,
						sku_type: liquidSku.sku_type,
						sku_description: liquidSku.sku_description,
					};
				}
			}
		});

		// Find any matching ones already and populate it
		console.log('SKUs', Object.values(skus));
		console.log('Existing SKUs', existingSkus);
		const mappedSkus = Object.values(skus).map((sku) => {
			const existingSku = existingSkus.find(
				(s) => s.sku_name.toLowerCase() === sku.sku_name.toLowerCase(),
			);
			if (existingSku) {
				return {
					...sku,
					mapped_sku: existingSku,
				};
			}
			return sku;
		});

		console.log('SKUs', mappedSkus);

		// Pop up a window to allow user to map SKUs..
		setImportSkuList(mappedSkus);
	}, [sku_sources, sources]);

	// Now that we know
	const onHandleSkuMappings = (skus) => {
		// Now we need to construct a delivery request with the details from this shipment
		// We need the back-end to handle any importing of assets, need to make sure they are migrated assets
		// So, now we can create a synthetic delivery with the details in the shipment
		setMappedSkuList(skus);
	};

	const handleAction = (key) => setActionKey(key);

	const handleDownload = useCallback(() => {
		// Convert JSON object to string
		const jsonString = JSON.stringify(
			{ ...(entity?.properties ?? {}), company_address: company?.company_address },
			null,
			2,
		); // null and 2 are for pretty printing with 2 spaces of indentation
		// Create a Blob with the JSON string and set its type to 'application/json'
		const blob = new Blob([jsonString], { type: 'application/json' });

		// Create a URL for the Blob
		const url = URL.createObjectURL(blob);

		// Create an anchor element and set the download attribute with a filename
		const a = document.createElement('a');
		a.href = url;
		a.download = `${entity?.properties?.report_type ? entity?.properties?.report_type : new Date(entity?.properties?.start_date ?? '').toLocaleDateString()}.json`;
		// Programmatically trigger a click event on the anchor element to start the download
		a.click();
		// Clean up by revoking the Blob URL
		URL.revokeObjectURL(url);
	}, [entity?.properties, company]);

	const handleDelivery = useCallback(() => {
		setDeliverySkus(sku_sources);
	}, [navigate, sku_sources]);

	const onCloseModal = useCallback(
		(forceRefresh) => {
			if (forceRefresh) {
				refresh();
			}
			setActionKey(null);
		},
		[refresh],
	);

	return (
		<Stack sx={styles} className='root' spacing={3}>
			<FlexBox>
				<H5>Request</H5>

				{request_status === REQUEST_STATUS.PENDING && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<PendingActionsTwoTone color='disabled' />
						<Overline>Pending</Overline>
					</Box>
				)}
				{request_status === REQUEST_STATUS.IN_PROGRESS && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<AccessTime color='secondary' />
						<Overline>In Progress</Overline>
					</Box>
				)}
				{request_status === REQUEST_STATUS.AWAITING_APPROVAL && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<FactCheck color='info' />
						<Overline>Awaiting Approval</Overline>
					</Box>
				)}
				{request_status === REQUEST_STATUS.DONE && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<CheckCircle color='success' />
						<Overline>Done</Overline>
					</Box>
				)}
				{request_status === REQUEST_STATUS.CANCELLED && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Cancel color='error' />
						<Overline>Cancelled</Overline>
					</Box>
				)}
				{request_status === REQUEST_STATUS.REJECTED && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<UnpublishedIcon color='error' />
						<Overline>Rejected</Overline>
					</Box>
				)}
				{request_status === REQUEST_STATUS.ARCHIVED && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Archive color='secondary' />
						<Overline>Archived</Overline>
					</Box>
				)}
			</FlexBox>

			<Box className='summary-qr'>
				<Box ref={printRef} className='qr'>
					<RequestQRCode request={entity} />
				</Box>

				{(showActions || shipment) && (
					<IconCircleButton
						onClick={() => handlePrint(printRef)}
						variant='outlined'
						color='secondary'
					>
						<Print height={24} width={24} />
					</IconCircleButton>
				)}
			</Box>

			{showActions &&
				canApprove &&
				request_status === REQUEST_STATUS.AWAITING_APPROVAL && (
					<Box className='section'>
						<H5 className='section-header'>Approve Request</H5>
						<Body1>This request needs to be approved.</Body1>
						<FlexBox justifyContent='flex-start'>
							<TitledButton
								handleClick={() => handleAction('approve_selected')}
								label='Approve'
								variant='outlined'
								color='secondary'
								sx={{ width: '72px', height: '55px' }}
							>
								<Done height={24} width={24} color='success' />
							</TitledButton>
							<TitledButton
								handleClick={() => handleAction('reject_selected')}
								label='Reject'
								variant='outlined'
								color='secondary'
								sx={{ width: '72px', height: '55px' }}
							>
								<Cancel height={24} width={24} color='error' />
							</TitledButton>
						</FlexBox>
					</Box>
				)}

			<FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
				{userIsActive && (
					<TitledButton
						handleClick={handleNew}
						variant='outlined'
						color='secondary'
						label='New'
						sx={{ width: '72px', height: '55px' }}
					>
						<AddCircle height={24} width={24} />
					</TitledButton>
				)}
				{userIsActive && sameCompany && (
					<TitledButton
						handleClick={handleClone}
						variant='outlined'
						color='secondary'
						label='Clone'
						sx={{ width: '72px', height: '55px' }}
					>
						<CopyAllOutlined height={24} width={24} />
					</TitledButton>
				)}

				{showActions &&
					[
						REQUEST_STATUS.PENDING,
						REQUEST_STATUS.IN_PROGRESS,
						REQUEST_STATUS.AWAITING_APPROVAL,
					].includes(request_status) && (
						<>
							{assignTask && (
								<TitledButton
									handleClick={() => handleAction('assign_selected')}
									label='Assign'
									variant='outlined'
									color='secondary'
									sx={{ width: '72px', height: '55px' }}
								>
									<AccountCircle height={24} width={24} />
								</TitledButton>
							)}
							<TitledButton
								handleClick={() => handleAction('cancel_selected')}
								label='Cancel'
								variant='outlined'
								color='secondary'
								sx={{ width: '72px', height: '55px' }}
							>
								<Delete height={24} width={24} color='error' />
							</TitledButton>
						</>
					)}
				{showImport && (
					<TitledButton
						handleClick={handleImport}
						label='Import'
						variant='outlined'
						color='secondary'
						sx={{ width: '72px', height: '55px' }}
					>
						<ImportantDevices height={24} width={24} />
					</TitledButton>
				)}
				{showDownload && (
					<TitledButton
						handleClick={handleDownload}
						label='Download'
						variant='outlined'
						color='secondary'
						sx={{ width: '72px', height: '55px' }}
					>
						<Download height={24} width={24} />
					</TitledButton>
				)}
				{showDelivery && (
					<TitledButton
						handleClick={handleDelivery}
						variant='outlined'
						color='secondary'
						label='Delivery'
						sx={{ width: '72px', height: '55px' }}
					>
						<Delivery height={24} width={24} />
					</TitledButton>
				)}
			</FlexBox>

			{showActions && (
				<>
					<CreatorProps entity={entity} />

					<AssigneeProps entity={entity} />
				</>
			)}

			{(canApprove || assignTask) && actionKey && (
				<TaskModal
					tasks={[requestCards]}
					users={users}
					onClose={onCloseModal}
					actionKey={actionKey}
				/>
			)}

			{!!importSkuList && (
				<ImportSKUModal
					open={!!importSkuList}
					skus={importSkuList}
					companyId={company_id}
					handleImportComplete={onHandleSkuMappings}
					onClose={() => setImportSkuList(null)}
				/>
			)}

			{!!mappedSkuList && (
				<ImportModal
					open={!!mappedSkuList}
					skus={mappedSkuList}
					entity={entity}
					onClose={() => setMappedSkuList(null)}
				/>
			)}

			{!!deliverySkus && (
				<DeliveryModal
					open={!!deliverySkus}
					skus={deliverySkus}
					entity={entity}
					onClose={() => setDeliverySkus(null)}
				/>
			)}
		</Stack>
	);
}

export default withSmallDialogCard(RequestSummary);
