import {
	CheckBoxRounded,
	KeyboardArrowLeft,
	KeyboardArrowRight,
} from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikCheckBox,
	FormikForm,
	FormikSelect,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import InventorySearch from '@pw/components/Search/InventorySearch';
import { Body2, H5, Overline } from '@pw/components/Typography';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import { ASSET_NAMES } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { useCompanySKUs } from '@pw/redux/containers/User';
import calculateSkuStorageLiquid from '@pw/utilities/calculateSkuStorageLiquid';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useGetId from '@pw/utilities/hooks/logic/useGetId';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useGetSKUEntriesLazyQuery } from '@pw/utilities/hooks/service/useGetSKUEntriesQuery';
import flatten from 'lodash.flatten';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

function SKUSelection({
	name,
	selection,
	skuEntryItems,
	unit,
	isSelected,
	toggleSelection,
}) {
	const { values } = useContext(FormikContext);
	const [storageAllocation, setStorageAllocation] = useState(null);

	const processSelection = useCallback(
		(item) => {
			if (isSelected(item)) {
				// Remove the amount..
				item.amount = 0;
				toggleSelection(item);
			} else {
				// Amount outstanding..
				const amount = values?.[name] ?? 0; // Total amount we need

				setStorageAllocation(item);
				// let selectedAmount =
				// 	selection?.reduce((v, i) => v + Number(i?.amount ?? 0), 0) ?? 0;
				// const storageList = flatten(skuEntryItems.map((e) => e.storage ?? []));
				// selectedAmount +=
				// 	storageList?.reduce((v, i) => v + Number(i?.amount ?? 0), 0) ?? 0;
				//
				// if (selectedAmount < amount) {
				// 	item.amount = amount - selectedAmount;
				// 	toggleSelection(item);
				// }
			}
		},
		[isSelected, toggleSelection, values, name, selection, skuEntryItems],
	);

	const handleStorageSelection = useCallback(
		(item) => {
			if (item && storageAllocation) {
				console.log('Storage selection', item);
				storageAllocation.amount = item.amount;
				toggleSelection(item);
			}
			setStorageAllocation(null);
		},
		[storageAllocation, toggleSelection],
	);

	if (!skuEntryItems || skuEntryItems.length === 0) return;

	return (
		<>
			<Stack spacing={0} className='list'>
				{skuEntryItems.map((entry) => (
					<Stack className='listItem storage-container' key={entry.path}>
						<Stack spacing={0.75} className='listContent'>
							<Stack
								spacing={0.5}
								sx={{ cursor: 'pointer', flexGrow: 1, textAlign: 'left' }}
							>
								<IDDisplay value={entry?.sku_id} />
								<SourceDisplay
									type={entry?.request_type}
									name={entry?.request_name}
								/>
							</Stack>
							{entry?.storage?.map((_storage) => (
								<SKUStorageItem
									key={_storage.asset_id}
									item={_storage}
									unit={unit}
									selected={isSelected(_storage)}
									onSelect={() => processSelection(_storage)}
								/>
							))}
						</Stack>
					</Stack>
				))}
			</Stack>
			{storageAllocation && (
				<StorageSetupModal
					open={!!storageAllocation}
					title='Pick'
					item={storageAllocation}
					onClose={handleStorageSelection}
				/>
			)}
		</>
	);
}

function SKUStorageItem({ item, unit, selected = false, onSelect }) {
	const {
		rw_asset_id,
		asset_type,
		amount,
		available_quantity,
		duty_paid,
		reserved_quantity,
	} = item;

	const onClick = useCallback(() => {
		if (onSelect) {
			onSelect(!selected);
		}
	}, [selected, onSelect]);

	return (
		<Box className='card' action={ASSET_NAMES[asset_type]} onClick={onClick}>
			<Box className='card-tab'>
				{selected && <CheckBoxRounded className='check' />}
			</Box>
			<Stack
				className={`card-content ${duty_paid ? 'card-highlight' : ''}`}
				spacing={0.25}
			>
				<IDDisplay value={rw_asset_id} />
				<DutyPaidDisplay value={duty_paid} />
				<Divider variant='middle' sx={{ opacity: 0.6 }} />
				<AmountDisplay amount={amount} unit={unit} />
				<AmountDisplay
					label='Reserved'
					amount={reserved_quantity}
					unit={unit}
				/>
				<AmountDisplay
					label='Available'
					amount={available_quantity}
					unit={unit}
				/>
			</Stack>
		</Box>
	);
}

function StorageItem({ item, onSelect, unit }) {
	return (
		<SKUStorageItem
			item={item}
			unit={unit}
			selected={item.selected ?? false}
			onSelect={(selected) => onSelect(item, selected)}
		/>
	);
}

function ChildList({ label, items, update, unit }) {
	const handleSelect = useCallback(
		(item, selected) => {
			// Deselect any other option (so only allow single selects);
			const updatedItems = items.map((i) => ({
				...i,
				selected: i.asset_id === item.asset_id ? selected : false,
			}));

			debounce(() => update(updatedItems), 25);
		},
		[update, items],
	);

	return (
		<Stack className='inventory' sx={{ width: '40%' }}>
			<Box className='inventory-header'>
				<Overline>{label}</Overline>
			</Box>
			<Box className='inventory-contents'>
				<Stack spacing={0.5}>
					{items.map((_item) => (
						<StorageItem
							key={_item.asset_id}
							item={_item}
							onSelect={handleSelect}
							unit={unit}
						/>
					))}
				</Stack>
			</Box>
		</Stack>
	);
}

function StorageSetupModalWrapper({ selected, ...props }) {
	const { values } = useContext(FormikContext);
	const left = Math.max(values?.amount ?? 0 - selected, 0);
	if (left === 0) {
		return null;
	}
	return <StorageSetupModal left={left} {...props} />;
}

function GeneralSourceSKU({
	item,
	onClose,
	include_tax_code = false,
	taxCodes = [],
	exportItems = false,
	calculateSkuLiquid = false,
}) {
	const companySkus = useCompanySKUs();
	const { enqueueSnackbar } = useSnackbar();
	const { path } = useGetId();

	const converter = useConverter();

	// We track the "amount" we want at the SKU Item level
	const {
		sku_id,
		sku_type,
		tax_code,
		amount,
		include_holder,
		entries = [],
		vendor,
		release,
		duty_paid = false,
	} = item ?? {};

	const skuItem = companySkus.find((s) => s.sku_id === sku_id);
	const { unit, releases = [] } = skuItem?.properties ?? {};

	const vendorOptions = useMemo(
		() => (skuItem?.vendors ?? []).map((v) => ({ label: v?.product, value: v })),
		[skuItem],
	);
	const releaseOptions = useMemo(
		() => (releases ?? []).map((v) => ({ label: v?.name, value: v })),
		[releases],
	);

	const [storageAllocation, setStorageAllocation] = useState(null);

	const existingStorage = useMemo(
		() => flatten(entries.map((e) => e.storage ?? [])),
		[entries],
	);

	const [available, , , upsertAvailable, removeAvailable] = useItemListManager(
		ID.asset,
		COMP.asset,
		[],
	);

	console.log('Available', available);

	const [selected, , , upsertSelected, removeSelected] = useItemListManager(
		ID.asset,
		COMP.asset,
		existingStorage.map((i) => ({ ...i, selected: false })),
	);

	const selectedCount = useMemo(
		() =>
			selected.reduce(
				(v, i) =>
					v +
					Number(
						i?.amount ??
							(i?.available_quantity ?? 0) - (i?.reserved_quantity ?? 0),
					),
				0,
			),
		[selected],
	);

	console.log('Selected', selectedCount);

	const finished = useMemo(() => sku_type === SKU_TYPES.FINISHED, [sku_type]);

	const [refetch, { data: skuEntryItems = [], error, isLoading }] =
		useGetSKUEntriesLazyQuery(entries);

	// const [selection, initSelection, toggleSelection, isSelected] = useSelections(
	// 	storage,
	// 	EQUAL.asset,
	// );

	const [ProgressBar, { setProgress }] = useProgress();

	const changeSet = useMemo(
		() => ({
			amount: [
				amount && unit ? converter.cx(amount ?? 0, null, unit) : amount ?? '',
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
			include_holder: [!!include_holder, yup.boolean()],
			...(include_tax_code
				? {
						tax_code: [
							tax_code ?? '',
							yup.string().required('Tax code required!'),
						],
					}
				: {}),
			vendor: [
				vendor,
				yup.object().shape({
					name: yup.string(),
					public_name: yup.string(),
					id: yup.string(),
					hash: yup.string(),
					type: yup.string(),
				}),
			],
			...(sku_type === SKU_TYPES.FINISHED
				? {
						release: [
							release,
							yup.object().shape({
								id: yup.string(),
								name: yup.string(),
								liquid_type: yup.string(),
							}),
						],
						duty_paid: [duty_paid, yup.boolean()],
					}
				: {}),
		}),
		[amount],
	);

	useEffect(() => {
		if (error) {
			enqueueSnackbar(error?.message, { variant: 'error' });
		}
	}, [error]);

	useEffect(() => setProgress(selectedCount), [selectedCount]);

	const handleSubmit = useCallback((values) => {
			try {
				let selectedAmount = 0;
				const entries = skuEntryItems.reduce((acc, entry) => {
					const storage = entry.storage
					.map((st) => selected.find((s) => s.asset_id === st.asset_id))
					.filter((st) => st) // only if selected
					.map((st) => {
						const liquid_amount = (finished && calculateSkuLiquid) ? calculateSkuStorageLiquid(converter, st.amount, skuItem, companySkus) : {};
						return {
							...st,
							amount: unit ? converter.cx(st.amount, unit) : amount,
							liquid_amount,
							selected: undefined
						};
					});

					// recalculate the amount used in this entry
					const amount = storage.reduce((v, i) => v + Number(i.amount), 0);
					selectedAmount += amount;
					acc.push({
						...entry,
						storage,
						amount: unit ? converter.cx(amount, unit) : amount,
					});
					return acc;
				}, []);

				console.log('Selected amount', selectedAmount, values.amount);

				// let requiredAmount = Number(values.amount);
				const sku = {
					...item,
					amount: unit ? converter.cx(values.amount, unit) : values.amount, // convert the amount back
					allocated_amount: unit ? converter.cx(selectedAmount, unit) : values.amount,

					include_holder: values.include_holder,
					vendor: values?.vendor,
					...(finished
						? {
							release: values?.release,
							duty_paid: values?.duty_paid,
							liquid_amount: (calculateSkuLiquid ? calculateSkuStorageLiquid(converter, values.amount, skuItem, companySkus) : {}),
							allocated_liquid_amount: (calculateSkuLiquid ? calculateSkuStorageLiquid(converter, selectedAmount, skuItem, companySkus) : {}),
						}
						: {}),
					entries,
					...(include_tax_code
						? {
								tax_code: values?.tax_code,
							}
						: {}),
				};
				console.log('Setting SKU', values, sku);
				onClose(sku);
			} catch (err) {
				enqueueSnackbar(err.message, { variant: 'error' });
			}
		},
		[item, finished, calculateSkuLiquid, skuItem, companySkus],
	);

	const onLoadEntries = useCallback(
		async (values) => {
			const params = {
				// amount: values?.amount,
				...values,
				sku_id,
				request_id: path,
			};
			const entryList = await refetch(params);
			console.log('Refetch', entryList);

			const storageList = flatten(entryList.map((e) => e.storage ?? [])).map(
				(i) => ({ ...i, amount: undefined }),
			);
			console.log('storageList', storageList);

			const unused = storageList.filter(
				(i) => !selected.find((s) => s.asset_id === i.asset_id),
			);
			console.log('Unused', unused);
			upsertAvailable(unused);
		},
		[path, refetch, sku_id, upsertAvailable, selected],
	);

	// const saveDisabled = useMemo(() => !(completion === 100), [completion]);

	const handleAdd = useCallback(() => {
		const selectedItems = available
			.filter((s) => s.selected)
			.map((s) => ({ ...s, selected: false }));
		if (selectedItems.length === 1) {
			setStorageAllocation(selectedItems[0]);

			debounce(() => {
				removeAvailable(selectedItems);
				// upsertSelected(selectedItems);
			}, 25);
		}
	}, [available, selected]);

	const handleRemove = useCallback(() => {
		const selectedItems = selected
			.filter((s) => s.selected)
			.map((s) => ({ ...s, amount: undefined, selected: false }));
		debounce(() => {
			removeSelected(selectedItems);
			upsertAvailable(selectedItems);
		}, 25);
	}, [available, selected]);

	const handleStorageSelection = useCallback(
		(item) => {
			if (item) {
				upsertSelected(item);
			}
			setStorageAllocation(null);
		},
		[storageAllocation, upsertSelected],
	);

	return (
		<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={2}>
				{sku_type === SKU_TYPES.FINISHED && (
					<>
						<FormikSelect
							label='Release'
							name='release'
							options={releaseOptions}
							fullWidth
						/>
						<FormikCheckBox name='duty_paid' label={<Body2>Duty Paid</Body2>} />
					</>
				)}
				<FormikSelect
					label='Vendor'
					name='vendor'
					options={vendorOptions}
					fullWidth
				/>
				<InventorySearch
					label='Amount'
					name='amount'
					unit={finished ? null : unit}
					fullWidth
					handler={onLoadEntries}
					searching={isLoading}
				/>

				{exportItems && (
					<FormikCheckBox
						label='Include Holder'
						name='include_holder'
						fullWidth
					/>
				)}
				{!!include_tax_code && (
					<FormikSelect
						label={'Tax Code'}
						name='tax_code'
						options={taxCodes}
						fullWidth
					/>
				)}

				<Instructions>Please select from available inventory.</Instructions>

				<ProgressBar name='amount' label='Amount' />

				<H5>Inventory</H5>
				<FlexBox alignItems='stretch' sx={{ position: 'relative' }}>
					<ChildList
						label='Available'
						items={available}
						update={upsertAvailable}
						unit={unit}
					/>
					<Stack sx={{ width: '10%' }}>
						<IconButton
							onClick={handleAdd}
							disabled={!available.some((s) => s.selected)}
							aria-label='Add'
						>
							<KeyboardArrowRight />
						</IconButton>
						<IconButton
							onClick={handleRemove}
							disabled={!selected.some((s) => s.selected)}
							aria-label='Remove'
						>
							<KeyboardArrowLeft />
						</IconButton>
					</Stack>
					<ChildList
						label='Selected'
						items={selected}
						update={upsertSelected}
						unit={unit}
					/>
				</FlexBox>

				<Errors />

				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton loading={isLoading} type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
			{storageAllocation && (
				<StorageSetupModalWrapper
					selected={selectedCount}
					open={!!storageAllocation}
					title='Pick'
					item={storageAllocation}
					onClose={handleStorageSelection}
				/>
			)}
		</FormikForm>
	);
}

export default GeneralSourceSKU;
