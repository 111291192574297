import StandardLabelPage from '@pw/pages/admin/labels/standard';
import QRLocator from '@pw/pages/QRLocator';
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useParams,
} from 'react-router-dom';
import AuthGuard from '@pw/components/Auth/AuthGuard';
import { COMPANY_FEATURES, COMPANY_TYPE } from '@pw/consts/company';
import { PERMISSIONS } from '@pw/consts/permissions';
import Custom403 from '@pw/pages/403';
import Custom404 from '@pw/pages/404';
import Custom426 from '@pw/pages/426';

import AccountSettingsPage from '@pw/pages/account/AccountSettings';
import OrganizationsPage from '@pw/pages/account/Organizations';
import CompanyPage from '@pw/pages/admin/Company';
import ContractsPage from '@pw/pages/admin/contracts';
import FacilitiesPage from '@pw/pages/admin/facilities';
import NABCALabelPage from '@pw/pages/admin/labels/nabca';
import OwnerPage from '@pw/pages/admin/Owners';
import PartnersPage from '@pw/pages/admin/Partners';
import RolesPage from '@pw/pages/admin/Roles';
import SensorsPage from '@pw/pages/admin/sensors';

import SKUCons from '@pw/pages/admin/skus/cons';
import SKUExp from '@pw/pages/admin/skus/exp';
import SKUExpenses from '@pw/pages/admin/skus/expenses';
import SKUFinished from '@pw/pages/admin/skus/finished';
import SKULiquid from '@pw/pages/admin/skus/liquid';
import SKUMarket from '@pw/pages/admin/skus/market';
import SKURaw from '@pw/pages/admin/skus/raw';
import SKUServices from '@pw/pages/admin/skus/services';
import SKUTracked from '@pw/pages/admin/skus/tracked';
import SKUWaste from '@pw/pages/admin/skus/waste';
import TeamsPage from '@pw/pages/admin/Teams';
import UsersPage from '@pw/pages/admin/Users';
import AssetTablePage from '@pw/pages/app/asset-table';
import CaskPage from '@pw/pages/app/cask';
import ChangeOwnershipPage from '@pw/pages/app/change-ownership';
import ContainerPage from '@pw/pages/app/container';
import DeliveryPage from '@pw/pages/app/delivery';
import DutySubmissionPage from '@pw/pages/app/duty-submission';
import DutyTransferPage from '@pw/pages/app/duty-transfer';
import ExplorerPage from '@pw/pages/app/explorer';
import IbcPage from '@pw/pages/app/ibc';
import InventoryAuditPage from '@pw/pages/app/inventory-audit';
import InvoicePage from '@pw/pages/app/invoice';
import LocationsPage from '@pw/pages/app/locations';
import PackPage from '@pw/pages/app/pack';
import PalletPage from '@pw/pages/app/pallet';
import PickPage from '@pw/pages/app/pick';
import ProductionDesigner from '@pw/pages/app/production-designer';
import ProductionRunPage from '@pw/pages/app/production-run';
import ProductionScheduler from '@pw/pages/app/production-scheduler';
import PurchaseOrderPage from '@pw/pages/app/purchase-order';
import ReguagePage from '@pw/pages/app/reguage';
import ReportDefectPage from '@pw/pages/app/report-defect';
import RestockPage from '@pw/pages/app/restock';
import SamplePage from '@pw/pages/app/sample';
import SearchPage from '@pw/pages/app/search';
import ShipPage from '@pw/pages/app/ship';
import SyncPage from '@pw/pages/app/sync';
import FillingTankPage from '@pw/pages/app/tank';
import TankerPage from '@pw/pages/app/tanker';
import TasksPage from '@pw/pages/app/tasks';
import TransferPage from '@pw/pages/app/transfer';
import DashBoard from '@pw/pages/Dashboard';
import LogoutPage from '@pw/pages/Logout';
import Recover from '@pw/pages/Recover';
import Register from '@pw/pages/Register';
import SignIn from '@pw/pages/SignIn';
import ExpenseReport from '@pw/pages/app/expense-report';

function RDRedirect({ base }) {
	let { platform, chain, address } = useParams();
	return <Navigate to={`${base}/${platform}/${chain}/${address}`} replace />;
}

function AppRoutes() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Navigate to='/app/overview' replace />} />
					<Route path='/signin' element={<SignIn />} />
					<Route path='/register' element={<Register />} />
					<Route path='/recover' element={<Recover />} />
					<Route path='/s' element={<QRLocator />} />

					{/**next config re routes start*/}
					<Route
						path='/pallet'
						element={<Navigate to='/app/pallet' replace />}
					/>
					<Route
						path='/logout'
						element={
							<AuthGuard>
								<LogoutPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/cask/edit/:platform/:chain/:address'
						element={<RDRedirect base='/app/cask' />}
					/>
					<Route
						path='/app/cask//rd/:platform/:chain/:address'
						element={<RDRedirect base='/app/cask' />}
					/>
					<Route
						path='/app/pallet//rd/:platform/:chain/:address'
						element={<RDRedirect base='/app/pallet' />}
					/>
					<Route
						path='/app/pallet/rd/:platform/:chain/:address'
						element={<RDRedirect base='/app/pallet' />}
					/>
					{/**next config re routes end*/}
					<Route
						path='/app/search'
						element={
							<AuthGuard>
								<SearchPage />
							</AuthGuard>
						}
					/>

					<Route
						path='/app/overview'
						element={
							<AuthGuard>
								<DashBoard />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/sync'
						element={
							<AuthGuard features={COMPANY_FEATURES.ALL}>
								<SyncPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/explorer'
						element={
							<AuthGuard>
								<ExplorerPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/tasks'
						element={
							<AuthGuard features={COMPANY_FEATURES.ALL}>
								<TasksPage />
							</AuthGuard>
						}
					/>

					<Route
						path='/app/locations'
						element={
							<AuthGuard>
								<LocationsPage />
							</AuthGuard>
						}
					/>

					{/*<Route*/}
					{/*	path='/app/reports/instant/:type/:nonce'*/}
					{/*	element={*/}
					{/*		<AuthGuard>*/}
					{/*			<ReportsInstantPage />*/}
					{/*		</AuthGuard>*/}
					{/*	}*/}
					{/*/>*/}
					{/*<Route*/}
					{/*	path='/app/reports/monthly/:type/:nonce'*/}
					{/*	element={*/}
					{/*		<AuthGuard>*/}
					{/*			<ReportsMonthlyPage />*/}
					{/*		</AuthGuard>*/}
					{/*	}*/}
					{/*/>*/}

					<Route
						path='/app/pallet/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<PalletPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/container/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<ContainerPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/cask/:platform?/:chain?/:address?'
						element={
							<AuthGuard>
								<CaskPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/ibc/:platform?/:chain?/:address?'
						element={
							<AuthGuard>
								<IbcPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/tank/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<FillingTankPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/tanker/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<TankerPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/transfer/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<TransferPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/pack/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.BOTTLING]}>
								<PackPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/pick/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<PickPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/duty-transfer/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<DutyTransferPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/duty-submission/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<DutySubmissionPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/purchase-order/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<PurchaseOrderPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/ship/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<ShipPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/sample/:platform?/:chain?/:address?'
						element={
							<AuthGuard>
								<SamplePage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/report-defect/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<ReportDefectPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/regauge/:platform?/:chain?/:address?'
						element={
							<AuthGuard>
								<ReguagePage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/restock/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<RestockPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/change-ownership/:platform?/:chain?/:address?'
						element={
							<AuthGuard
								types={[COMPANY_TYPE.BROKER]}
								features={[COMPANY_FEATURES.WAREHOUSING]}
							>
								<ChangeOwnershipPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/inventory-audit/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<InventoryAuditPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/delivery/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<DeliveryPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/production-run/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
								<ProductionRunPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/invoice/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<InvoicePage />
							</AuthGuard>
						}
					/>
					<Route
						path='/app/expense-report/:platform?/:chain?/:address?'
						element={
							<AuthGuard features={[COMPANY_FEATURES.WAREHOUSING]}>
								<ExpenseReport />
							</AuthGuard>
						}
					/>

					<Route
						path='/app/asset-table'
						element={
							<AuthGuard>
								<AssetTablePage />
							</AuthGuard>
						}
					/>

					<Route
						path='/app/production-designer'
						element={
							// <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
							<ProductionDesigner />
							// </AuthGuard>
						}
					/>

					<Route
						path='/app/production-scheduler'
						element={
							// <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
							<ProductionScheduler />
							// </AuthGuard>
						}
					/>

					<Route
						path='/admin/company'
						element={
							<AuthGuard permissions={[PERMISSIONS.COMPANY_ADMIN]}>
								<CompanyPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/labels/nabca'
						element={
							<AuthGuard>
								<NABCALabelPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/labels/standard'
						element={
							<AuthGuard>
								<StandardLabelPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/owners'
						element={
							<AuthGuard>
								<OwnerPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/partners'
						element={
							<AuthGuard>
								<PartnersPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/facilities'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.FACILITY_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<FacilitiesPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/cons/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKUCons />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/exp/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKUExp />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/finished/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKUFinished />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/liquid/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKULiquid />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/market/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKUMarket />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/raw/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKURaw />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/services/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKUServices />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/expenses/:id?'
						element={
							// <AuthGuard
							// 	permissions={[PERMISSIONS.SKU_ADMIN]}
							// 	features={COMPANY_FEATURES.ALL}
							// >
							<SKUExpenses />
							// </AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/tracked/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKUTracked />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/skus/waste/:id?'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SKU_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SKUWaste />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/users'
						element={
							<AuthGuard permissions={[PERMISSIONS.USER_ADMIN]}>
								<UsersPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/roles'
						element={
							<AuthGuard permissions={[PERMISSIONS.USER_ADMIN]}>
								<RolesPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/teams'
						element={
							<AuthGuard permissions={[PERMISSIONS.USER_ADMIN]}>
								<TeamsPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/contracts'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.COMPANY_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<ContractsPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/admin/sensors'
						element={
							<AuthGuard
								permissions={[PERMISSIONS.SENSOR_ADMIN]}
								features={COMPANY_FEATURES.ALL}
							>
								<SensorsPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/account/settings'
						element={
							<AuthGuard>
								<AccountSettingsPage />
							</AuthGuard>
						}
					/>
					<Route
						path='/account/organizations'
						element={
							<AuthGuard>
								<OrganizationsPage />
							</AuthGuard>
						}
					/>

					<Route path='/not-found' element={<Custom404 />} />
					<Route path='/not-online' element={<Custom403 />} />
					<Route path='/upgrade' element={<Custom426 />} />
					<Route path='*' element={<Custom404 />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}
export default AppRoutes;
