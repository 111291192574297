import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withDialogCard from '@pw/components/Cards/DialogCard';
import ExplorerLocation from '@pw/components/ExplorerLocation';
import { explorerLocationFields } from '@pw/components/ExplorerLocation/explorerLocationFields';
import ExplorerTable, { BrokerTable } from '@pw/components/ExplorerTable';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { paginationFields } from '@pw/components/Pagination/paginationFields';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import {
	useCompanyFacilities,
	useIsBrokerCompany,
} from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import { useMemo } from 'react';
import * as yup from 'yup';

function Explorer() {
	const facilities = useCompanyFacilities();
	const isBrokerCompany = useIsBrokerCompany();

	const changeSet = useMemo(
		() => ({
			...(isBrokerCompany
				? { partner_id: ['', yup.string().required('Company is required!')] }
				: { location: explorerLocationFields({}) }),
			...paginationFields('12'),
		}),
		[isBrokerCompany],
	);

	usePageTitleHook('Explorer');

	return (
		<Box sx={styles} className='root'>
			<FormikForm changeSet={changeSet}>
				{isBrokerCompany ? (
					<BrokerTable />
				) : (
					<Stack spacing={3}>
						<ExplorerLocation
							name='location'
							label='Location'
							facilities={facilities}
						/>
						<ExplorerTable />
					</Stack>
				)}
			</FormikForm>
		</Box>
	);
}

export default withAppLayout(withDialogCard(Explorer));
